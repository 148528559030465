import axios, { AxiosResponse } from "axios";

const putUser = async (
  key: string,
  id: number,
  payload,
  onSuccess = (res) => {},
  onError = (err) => {}
) => {
  const url: string | undefined = process.env.REACT_APP_URL;
  const fullUrl: string = `${url}/users/${id}`;

  try {
    const res: AxiosResponse = await axios.put(fullUrl, payload);
    onSuccess(res.data);
  } catch (error) {
    onError(error);
  }
};

export default putUser;
