import styled from 'styled-components';

const ImageDiv = styled.div`
  transition: 0.4s linear;
  overflow-y: hidden;
  
  &:hover {
    opacity: 0.75;
  }

  @media (max-width: 630px) {
    height: 100%;
    width: 100%;
    border-right: 1px solid #ccc;
  }
`;

const ProductFrame = styled.div`
  padding: 1rem;
  width: 33.3%;
  @media (max-width: 1024px) {
    padding: 0.5rem;
  }
  @media (max-width: 768px) {
    width: 50%;
  }
  @media (max-width: 630px) {
    padding: 0.5rem 0;
    width: 100%;
  }
`;

const ProductDetailWrapper = styled.div`
  margin: 0.5rem;
  @media (max-width: 630px) {
    padding-left: 10px;
    margin: 0;
    width: 66.6%;
    display: flex;
    flex-direction: column;
  }
`;

const Name = styled.p`
  text-transform: uppercase;
  height: 70px;
  text-align: center;
  font-weight: 600;
  @media (max-width: 630px) {
    font-size: 14px;
    height: 50px;
    margin-top: 12px;
    text-align: left;
  }
`;
const Ref = styled.p`
  text-transform: uppercase;
  color: #666;
  @media (max-width:630px) {
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;
const CardContainer = styled.div`
  border: 1px solid #ccccccab;
  height: 100%;
  width: 100%;
  @media (max-width: 630px) {
    display: flex;
    flex-direction: row;
  }
`;

const Price = styled.p`
  color: #446932;
  font-weight: 800;
`;

const Image = styled.img`
  width: 100%;
  height: 150px;
  margin: 1px 0;
  object-fit: contain;
  border-bottom: 1px solid #ccc;
  object-position: top;
  @media (max-width: 630px) {
    height: 100%;
  }
`;

export { Image, Price, CardContainer, Ref, Name, ImageDiv, ProductFrame, ProductDetailWrapper };
