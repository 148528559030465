import React, { useState } from "react";
import img1 from "../../../images/welcom-frame-img-2.jpg";
import img2 from "../../../images/welcom-frame-img-4.jpg";
import styled from "styled-components";
import AboutUsButton from "../../pieces/small/AboutUsButton";
import { texts } from "./Texts";
import img3 from "../../../images/tab-img.jpg";
import "./Empresa.css";
import Container from "../../pieces/small/Container";
import { Row, Text } from "../../../styledMain";

const MobileBottomWrapper = styled.div`
  @media (min-width: 501px) {
    display: none;
  }
`;

const LeftWrapper = styled.div`
  width: 41.66%;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const BottomWrapper = styled(Row)`
  margin-top: 8rem;
  @media (max-width: 1024px) {
    margin-top: 4rem;
  }
  @media (max-width: 500px) {
    display: none;
  }
`;

const ImageWrapper = styled.div`
  width: 50%;
  position: relative;
  @media (max-width: 1024px) {
    display: none;
  }
`;

const TextWrapper = styled.div`
  width: 50%;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const SelectionWrapper = styled.div`
  margin-top: 8rem;
  margin-bottom: 2rem;
  transition: 0.1s linear;
  @media (max-width: 1024px) {
    margin-bottom: 1rem;
  }
  @media (max-width: 500px) {
    margin-top: 0;
    border-top: 1px solid #ccc;
    padding-top: 10px;
  }
`;

const RightWrapper = styled.div`
  width: 58.33%;
  @media (max-width: 1024px) {
    display: none;
  }
`;

const ButtonContainer = styled.div`
  border: 1px solid #ccc;
  display: flex;
  flex-direction: row;
  @media (max-width: 500px) {
    display: none;
  }
`;

const containerCss: string = `@media(max-width: 1024px){margin: 10px 20px;padding-top: 20px;}@media(max-width: 500px){margin: 10px;}`;

const Empresa: React.FC = () => {
  const [currentSection, setCurrentSection] = useState<string>("empresa");

  const toggleTexts = (e) => {
    const section = e.target.id;
    setCurrentSection(section);
  };

  return (
    <Container css={containerCss}>
      <div className="flex flex-row historia">
        <LeftWrapper>
          <span>ALFLORA</span>
          <h2>História</h2>
          <Text>A 8 de Abril de 1993, Alfeu Augusto Gonçalves e Fernando Pinto Gonçalves, fundaram a Alflora - Comércio de Flores. A oportunidade de negócio surgiu quando os sócios sentiram uma lacuna no mercado profissional de floristas, que tinham grande dificuldade em encontrar armazéns especializados em arte floral, apenas dedicados a flores e acessórios. Com uma área inicial de venda de apenas 225 m2, a Alflora depressa teve necessidade de expandir o seu espaço, contando hoje com cerca de 2500 m2 de área de exposição e 3000 m2 de armazém. O que em tempos era realizado por apenas duas pessoas, rapidamente se tornou incomportável, sendo agora uma equipa de 15 pessoas que asseguram diariamente todos os serviços.</Text>
        </LeftWrapper>
        <RightWrapper className="relative empresa-imgs p-6">
          <div className="gallery">
            <div className="black-1"></div>
            <img src={img1} alt="about-us-1" className="img-1" />
            <div className="black-2"></div>
            <img src={img2} alt="about-us-2" className="img-2" />
          </div>
        </RightWrapper>
      </div>
      <SelectionWrapper>
        <ButtonContainer>
          <AboutUsButton id="empresa" onClick={toggleTexts} current={currentSection}>
            Empresa
          </AboutUsButton>
          <AboutUsButton id="espaco" onClick={toggleTexts} current={currentSection}>
            Espaço
          </AboutUsButton>
          <AboutUsButton id="missao_conceito" onClick={toggleTexts} current={currentSection}>
            Missão e Conceito
          </AboutUsButton>
        </ButtonContainer>
        <BottomWrapper>
          {texts[currentSection].side === "right" ? (
            <>
              <TextWrapper className="z-50">{texts[currentSection].jsx}</TextWrapper>
              <ImageWrapper>
                <div className="image-wrapper right ml-8">
                  <img src={img3} alt="img-tab" />
                </div>
              </ImageWrapper>
            </>
          ) : (
            <>
              <ImageWrapper>
                <div className="image-wrapper left mr-8">
                  <img src={img3} alt="img-tab" />
                </div>
              </ImageWrapper>
              <TextWrapper className="z-50">{texts[currentSection].jsx}</TextWrapper>
            </>
          )}
        </BottomWrapper>
        <MobileBottomWrapper>
          <TextWrapper className="z-50">{texts.empresa.jsx}</TextWrapper>
          <TextWrapper className="z-50">{texts.espaco.jsx}</TextWrapper>
          <TextWrapper className="z-50">{texts.missao_conceito.jsx}</TextWrapper>
        </MobileBottomWrapper>
      </SelectionWrapper>
    </Container>
  );
};

export default React.memo(Empresa);
