import React, { useRef, useState } from 'react';
import Input from '../../pieces/small/Input';
import { useHistory } from 'react-router-dom';
import useInput from '../../../hooks/useInput';
import { Link, PassP } from './styled';
import { useCookies } from 'react-cookie';
import { Box, Column, Row } from '../../../styledMain';
import PassModal from '../../pieces/large/PassModal/PassModal';
import styled from 'styled-components';
import postUser from '../../../api/postUser';
import useRegister from '../../../hooks/useRegister';
import CountryInput from '../../pieces/medium/CountryInput/CountryInput';
import useLoading from '../../../hooks/useLoading';
import login from '../../../api/login';
import LoadingSpinner from '../../pieces/small/LoadingSpinner';
import _ from "lodash"

const validateEmail = (mail: string) => {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
    return true;
  }

  return false;
};

const Button = styled.button`
  background: ${process.env.REACT_APP_2COLOR};
  width: 100%;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1.5px;
  padding: 10px 7px;
  margin: 1.5rem auto;
  border-radius: 2px;
  color: #f1f1f1;
  transition: all 0.4s ease 0s;
  ${({ css }) => css && `${css}`}
`;

const Title = styled.h2`
  color: ${process.env.REACT_APP_2COLOR};
  text-transform: uppercase;
  margin-bottom: 2rem;
`;

const StringError = styled.p`
  background: #ccc;
  transition: 0.2s linear;
  border-radius: 2px;
  color: white;
  padding: 5px;
  margin-top: 0;
  text-transform: uppercase;
  font-weight: 800;
`;

const LoginContainer = styled.div`
  padding: 2.5rem 4rem;
  transition: 0.2s linear;
  @media (max-width: 768px) {
    padding: 0 20px;
  }
  @media (max-width: 500px) {
    padding: 0 10px;
  }
`;

const ErrorP = styled.p`
  text-align: center;
  margin-top: 20px;
  margin-bottom: 0;
`;

//CSS EXTRA
const responsiveWidth: string = `
  width: 50%;
  @media(max-width: 768px){
    width: 100%;
    padding: 0 !important;
  }
`;

const leftColCss: string = `${responsiveWidth} padding-right: 30px;`;
const rightColCss: string = `${responsiveWidth} padding-left: 30px;`;
const buttonColCss: string = `width: 50%;margin-left: auto;@media(max-width: 425px){width: 100%;}`;

type LoginProps = {
  setLogData: (object: object) => void;
};

//function validateEmail(sEmail) {
//  var reEmail = /^(?:[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+\.)*[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+@(?:(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!\.)){0,61}[a-zA-Z0-9]?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!$)){0,61}[a-zA-Z0-9]?)|(?:\[(?:(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\]))$/;
//
//  if (!sEmail.match(reEmail)) {
//    console.log(sEmail);
//    return false;
//  }
//
//  return true;
//}

const Login: React.FC<LoginProps> = ({ setLogData }) => {
  const [email, bindEmail, resetEmail] = useInput('');
  const [pass, bindPass, resetPass] = useInput('');
  const [, setCookie] = useCookies();
  const [error, setError] = useState<boolean>(false);
  const [loginLoading, loginLoadTools] = useLoading(true);
  const [popupOpen, setPopupOpen] = useState<boolean>(false);
  const history = useHistory();
  const passRef = useRef<HTMLInputElement>(null);
  //REGISTER
  const { bind, properties } = useRegister();
  const [stringError, setStringError] = useState<string>(null);
  const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
  const [registerLoading, registerLoadTools] = useLoading(false);

  //LOGIN SUCCESS
  const onSuccess = (res) => {
    
    resetEmail();
    resetPass();
    setCookie('alflora-key', res.data.token, { maxAge: 12000 });
    setCookie('alflora-user', _.omit(res.data.user, ["addresses", "entity.deliveryAddress", "entity.addresses"]), { maxAge: 12000 });
    //history.push('/home');
    window.location.replace("/#/home")
    window.location.reload()
  };

  //-------------------
  const cancelRegister = () => {
    window.scrollTo(0, 0);
    setIsEmailValid(false);
    properties.tools.resetAll();
  };

  const confirmEmail = () => {
    const isValid: boolean = validateEmail(properties.vals.email);
    if (isValid) {
      window.scrollTo(0, 0);
      setIsEmailValid(isValid);
    } else properties.error.add('email');
  };

  const onKeyDownEmail = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13 && !isEmailValid) confirmEmail();
  };

  const register = () => {
    const hasErrors = properties.error.checkForErrors();

    if (!hasErrors) {
      window.scrollTo(0, 0);
      registerLoadTools.start();
      postUser(
        properties.dataToPost,
        (res) => {
          login(setLogData, { email: properties.vals.email, password: properties.dataToPost.password }, onSuccess, () => {});
        },
        (res) => {
          registerLoadTools.loaded();
          setStringError('O email inserido já se encontra em utilização');
        }
      );
    }
  };

  //LOGIN LOGIC
  const log = () => {
    login(setLogData, { email, password: pass }, onSuccess, (err) => {
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 2500);
    });
  };

  const nextInput = (e: React.KeyboardEvent) => {
    const code: number = e.keyCode;
    if (code === 13 && passRef && passRef.current) {
      passRef.current.focus();
    }
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  const openPopup = () => {
    setPopupOpen(true);
  };

  const keyLogin = (e: React.KeyboardEvent) => {
    const code: number = e.keyCode;
    if (code === 13) {
      log();
    }
  };

  return (
    <>
      <PassModal open={popupOpen} close={closePopup} />
      <LoginContainer>
        <Row>
          <Column css={leftColCss}>
            <Title>Entre na sua conta Alflora</Title>
            <Input placeholder="Email do Utilizador" onKeyDown={nextInput} {...bindEmail} type="email" />
            <Input placeholder="Palavra-passe" {...bindPass} type="password" ref={passRef} onKeyDown={keyLogin} />
            {error && <ErrorP>Palavra-Passe ou email errado, tente novamente.</ErrorP>}
            <Row>
              <Column css="margin-top: 15px;">
                <PassP className="text-center text-md my-auto" onClick={openPopup}>
                  Esqueceu-se da palavra-passe?
                </PassP>
              </Column>
              <Column css={buttonColCss}>
                <Button onClick={log}>Entrar</Button>
              </Column>
            </Row>
          </Column>
          <Column css={rightColCss}>
            <Title>Criar Conta Alflora</Title>
            {!registerLoading ? (
              <>
                {stringError && <StringError className="text-center w-full">{stringError}</StringError>}
                <Input
                  placeholder="Email do Utilizador"
                  {...bind.email}
                  type="email"
                  onKeyDown={onKeyDownEmail}
                  readOnly={isEmailValid ? true : false}
                />
                {isEmailValid && (
                  <Column>
                    <Input placeholder="Nome*" {...bind.name} wrong={properties.error.hasError('name')} />
                    <Input placeholder="NIF*" {...bind.vatNumber} wrong={properties.error.hasError('vatNumber')} type="number" />
                    <Input
                      placeholder="Telefone*"
                      {...bind.mobilePrivate}
                      wrong={properties.error.hasError('mobilePrivate')}
                      type="number"
                    />
                    <Row>
                      <Column css="width: 33.3%;padding-right: 5px;@media(max-width: 768px){width: 100%; padding: 0;}">
                        <CountryInput bind={bind.country} wrong={properties.error.hasError('country')} properties={properties} />
                      </Column>
                      <Column css="width: 33.3%;padding-left: 5px;padding-right: 5px;@media(max-width: 768px){width: 50%; padding-left: 0;}">
                        <Input placeholder="Morada*" {...bind.addressLine1} wrong={properties.error.hasError('addressLine1')} />
                      </Column>
                      <Column css="width: 33.3%;padding-left: 5px;@media(max-width: 768px){width: 50%; padding-left: 5px}">
                        <Input placeholder="Morada (cont.)" {...bind.addressLine2} />
                      </Column>
                    </Row>
                    <Row>
                      <Column css="width: 50%;padding-right: 5px;">
                        <Input placeholder="Código Postal*" {...bind.postalCode} wrong={properties.error.hasError('postalCode')} />
                      </Column>
                      <Column css="width: 50%;padding-left: 5px;}">
                        <Input placeholder="Cidade" {...bind.city} />
                      </Column>
                    </Row>
                    <Row>
                      <Column css="width: 50%;padding-right: 5px;">
                        <Input placeholder="Password*" {...bind.password} type="password" />
                      </Column>
                      <Column css="width: 50%;padding-left: 5px;}">
                        <Input
                          placeholder="Confirmar Password*"
                          type="password"
                          wrong={properties.error.hasError('confirmPassword')}
                          {...bind.confirmPassword}
                        />
                      </Column>
                    </Row>
                  </Column>
                )}
                <Row css="width: 100%;">
                  {isEmailValid && (
                    <Column>
                      <PassP className="text-md my-auto" onClick={cancelRegister}>
                        Cancelar
                      </PassP>
                    </Column>
                  )}
                  <Column css={buttonColCss}>
                    {isEmailValid ? <Button onClick={register}>Registar</Button> : <Button onClick={confirmEmail}>Continuar</Button>}
                  </Column>
                </Row>
              </>
            ) : (
              <LoadingSpinner />
            )}
          </Column>
        </Row>
      </LoginContainer>
    </>
  );
};

export default React.memo(Login);
//<p className="text-center text-xl">
//          Ainda não se registou? <Link to="/register">Crie uma conta</Link>.
//        </p>
